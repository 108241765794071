<template>
  <v-row>
    <v-col cols="12">
      <p>
        月の売上目標や、売上達成率などを確認できます。
        まずは売上目標額を設定しましょう。
      </p>
      <router-link to="/goals/settings">売上目標の設定はこちら</router-link>
      <v-img
        :src="require('@/assets/images/modals/sales-figure-modal-1.png')"
      ></v-img>

      <div class="text-decoration-underline font-weight-bold">実績</div>
      <p>本日までの累計売上(freeeより自動抽出)</p>

      <div class="text-decoration-underline font-weight-bold">予定売上</div>
      <p>目標を達成するため、本日までに達成しているべき目標の数値</p>

      <div class="text-decoration-underline font-weight-bold">目標</div>
      <p>設定した目標売上</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeSalesFigureModal"
};
</script>

<style scoped></style>
